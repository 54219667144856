import { Player } from '@lottiefiles/react-lottie-player';
import { FunctionComponent, memo } from 'react';

const LazyLottie: FunctionComponent<{ data: object; style?: any }> = ({
  data,
  style,
}) => {
  return <Player autoplay loop src={data} style={style} />;
};

export default memo(LazyLottie);
